$bgcolor:#2075F5;
$color:#ffff;


.card{
    width: 300px;
    height: 200px;

   
}

#icons ,#cardbox{
    margin: 5px;
}
