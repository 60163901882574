// #blogHead {
//   width: 100%;
//   display: block;
//   padding-top: 40px;
//   padding-left: 120px;
//   overflow: hidden;

//   #title {
//     margin: 10px 0px;
//     h2 {
//       text-align: start;
//       font-family: "Poppins";
//       font-style: normal;
//       font-weight: 600;
//       font-size: 40px;
//       line-height: 130%;
//       color: #000000;
//     }
//   }

//   #subTitle {
//     margin: 10px 0px;
//     p {
//       text-align: start;
//       font-family: "Poppins";
//       font-style: normal;
//       font-weight: 400;
//       font-size: 16px;
//       line-height: 30px;
//       text-transform: capitalize;

//       color: #000000;
//     }
//   }
//   #BlogButton {
//     display: flex;
//     justify-content: start;
//     margin: 20px 0px;
//     button {
//       width: 152px;
//       height: 50px;
//       margin: 0px 20px 0px 0px;
//       border: 1px solid #162337;
//       text-align: center;
//       color: white;
//       background: #162337;
//       font-family: "Poppins";
//       font-style: normal;
//       font-weight: 600;
//       font-size: 22px;
//       line-height: 130%;
//       letter-spacing: 0.9px;
//       /* or 31px */
//     }
//   }
//   hr {
//     width: 855px;
//     height: 0px;
//     border: 1px solid #000000;
//     margin: 20px 0px;
//     background-color: black;
//   }
// }

// //blog middle degines

// #blogMiddle {
//   display: flex;
//   justify-content: center;
//   flex-wrap: wrap;
//   position: relative;
//   // padding: 20px 0px;
//   overflow: hidden;
//   margin: 20px 0px;
//   z-index: 1;
//   #BlogMiddlebox {
//     margin: 0px 0px 0px 0px;
//     img {
//       // width: 553px;
//       // height: 400px;
//       border-radius: 20px;
//     }
//     #clender {
//       text-align: end;
//     }
//   }
//   #blogMiddletwo {
//     // width: 526px;
//     height: 189px;
//     // margin: 200px 0px 0px 0px;
//     p {
//       // top: 1320px;
//       // margin: 100px 0px 0xp 0xp;
//       font-family: "Poppins";
//       text-align: start;
//       font-style: normal;
//       font-weight: 600;
//       font-size: 28px;
//       line-height: 150%;

//       color: #1c2757;
//     }
//     a {
//       font-family: "Poppins";
//       font-style: normal;
//       font-weight: 400;
//       font-size: 20px;
//       line-height: 30px;
//       text-transform: capitalize;

//       // color: $color;
//     }
//   }
// }

// #blogcards {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;

//   #Card {
//     margin: 20px;

//     #imgbox {
//       // height: 300px;
//     }
//     #card-body {
//       display: flex;
//       justify-content: space-between;
//       p {
//         width: 112px;
//         padding: 5px 0px;
//         font-family: "Poppins";
//         font-style: normal;
//         font-weight: 600;
//         font-size: 14px;
//         line-height: 130%;
//         color: #ffffff;
//         background: #f1b985;
//         text-align: center;
//         border-radius: 15px;
//       }
//     }
//     #card-text {
//       margin: 10px 0px 0px 0px;
//       p {
//         font-family: "Poppins";
//         font-style: normal;
//         font-weight: 600;
//         font-size: 24px;
//         line-height: 130%;
//         /* or 31px */

//         text-transform: capitalize;
//         width: 317px;

//         color: #1c2757;
//       }
//     }
//     a {
//       width: 150px;
//       height: 31px;
//       font-family: "Poppins";
//       font-style: normal;
//       font-weight: 400;
//       font-size: 20px;
//       line-height: 30px;
//       text-transform: capitalize;

//       color: #004ce0;
//     }
//   }
// }

// /* On screens that are 600px wide or less, the background color is olive */
// @media screen and (max-width: 1024px) {
//   #blogHead #title h2 {
//     font-size: 30px;
//   }
//   #blogHead #subTitle p {
//     font-size: 12px;
//     line-height: 30px;
//   }
//   #blogHead #BlogButton button {
//     width: 104px;
//     height: 35px;
//     margin: 0px 20px 0px 0px;

//     font-weight: 600;
//     font-size: 14px;
//     line-height: 108%;
//     letter-spacing: 0.9px;
//   }
//   #blogHead {
//     width: 100%;
//     display: block;
//     padding-top: 26px;
//     padding-left: 81px;
//     overflow: hidden;
//   }

//   #blogHead hr {
//     width: 651px;
//   }

// }

// @media screen and (max-width: 768px) {
//   #blogHead #title h2 {
//     font-size: 24px;
//   }
//   #blogHead #subTitle p {
//     font-size: 10px;
//     line-height: 20px;
//   }
//   #blogHead #BlogButton button {
//     width: 83px;
//     height: 27px;
//     margin: 0px 15px 0px 0px;
//     font-weight: 600;
//     font-size: 12px;
//     line-height: 86%;
//     letter-spacing: 0.9px;
//   }
//   #blogHead {
//     width: 100%;
//     display: block;
//     padding-top: 10px;
//     padding-left: 45px;
//     overflow: hidden;
//   }

//   #blogHead hr {
//     width: 400px;
//   }
// }

// @media screen and (max-width: 425px) {
//   #blogHead #title h2 {
//     font-size: 16px;
//   }
//   #blogHead #subTitle p {
//     font-size: 5px;
//     line-height: 12px;
//   }
//   #blogHead #BlogButton button {
//     width: 37px;
//     height: 24px;
//     margin: 0px 9px 0px 0px;
//     font-weight: 600;
//     font-size: 7px;
//     line-height: 68%;
//     letter-spacing: 0.9px;
//   }
//   #blogHead {
//     width: 100%;
//     display: block;
//     padding-top: 10px;
//     padding-left: 15px;
//      overflow: hidden;
//   }

//   #blogHead hr {
//     width: 200px;
//   }
// }

.Blog {
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: white !important;
    background: #162337 !important;
    /* background-color: var(--bs-nav-pills-link-active-bg); */
  }
  a.nav-link {
    color: #000000 !important;
    border: 1px solid #000000 !important;
  }
}
