#Aboutheadsection {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 0px;

  #imgbox {
    margin: 20px 35px;
    img {
      width: 450px;
    }
  }

  #Aboutcontentbox {
    margin: 20px 35px;
    width: 500px;
    text-align: start;
    text-decoration: none;
    font-family: "Times New Roman", Times, serif;
    text-transform: capitalize;
    font-size: 14px;

    h2 {
      margin-top: 24px;
      margin-bottom: 20px;
      color: black;
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
      letter-spacing: 0.3px;
    }
  }
}

//head section degins end

#aboutlowersection {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: block;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      to right,
      rgba(15, 15, 15, 0.212),
      rgba(24, 23, 23, 0.37)
    ),
    url("../../assets/Img/about.png");

  #middlecardbox {
    display: flex;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
    padding: 20px;
    #aboutcardOne {
      width: 320px;
      padding: 0px 30px;
      height: 346px;
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
      border-radius: 15px;
      margin: 20px;

      i {
        font-size: 50px;
        color: #f24824;
      }

      p {
        margin: 20px 0px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 182.9%;
        color: #515151;
        width: 256px;
        height: 135px;
        //
        text-transform: capitalize;
      }

      &:hover {
        background-color: #f24824;
        color: white;
      }
    }

    #aboutcardOne:hover i {
      color: white;
      width: 320px;
      height: 346px;
      border-radius: 15px;
    }

    #aboutcardOne:hover p {
      color: white;
      width: 320px;
      height: 346px;
      border-radius: 15px;
      width: 256px;
      height: 135px;
    }

    #aboutcardtwo {
      width: 320px;
      height: 346px;
      padding: 0px 30px;
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
      border-radius: 15px;
      margin: 20px;

      &:hover {
        background-color: #08b4e3;
      }

      i {
        font-size: 50px;
        color: #08b4e3;
      }

      p {
        margin: 20px 0px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 182.9%;
        color: #515151;
        width: 256px;
        height: 135px;
        text-transform: capitalize;
      }

      &:hover {
        background-color: #08b4e3;
        color: white;
      }
    }

    #aboutcardtwo:hover i {
      color: white;
      width: 320px;
      height: 346px;
      border-radius: 15px;
    }

    #aboutcardtwo:hover p {
      color: white;
      width: 320px;
      height: 346px;
      border-radius: 15px;
      width: 256px;
      height: 135px;
    }

    #aboutcardthree {
      width: 320px;
      height: 346px;
      padding: 0px 30px;
      background: #ffffff;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
      border-radius: 15px;
      margin: 20px;

      i {
        font-size: 50px;
        color: #01a977;
      }

      p {
        margin: 20px 0px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 182.9%;
        color: #515151;
        width: 256px;
        height: 135px;
        //
        text-transform: capitalize;
      }

      &:hover {
        background-color: #01a977;
        color: white;
      }
    }

    #aboutcardthree:hover i {
      color: white;
      width: 320px;
      height: 346px;
      border-radius: 15px;
    }

    #aboutcardthree:hover p {
      color: white;
      width: 320px;
      height: 346px;
      border-radius: 15px;
      width: 256px;
      height: 135px;
    }
  }
}

//last section degines end

#aboutLastsection {
  width: 100%;
  margin: 30px 0px;
  // #aboutLowerHead {
  //   width: 100%;
  //   display: flex;
  //   position: relative;

  //   justify-content: center;

  //   #h2degine {
  //     background-color: #ebf3fa;
  //     border-radius: 25px;
  //     margin: 20px 0px;
  //     width: 156px;
  //     height: 45px;

  //     text-align: center;

  //     h2 {
  //       font-style: normal;
  //       font-weight: 400;
  //       font-size: 20px;
  //       padding: 10px 0px;
  //       line-height: 24px;
  //       text-transform: capitalize;
  //       text-align: center;
  //       font-family: "Montserrat";
  //       color: #0065ae;
  //     }
  //   }
  // }
  #boxone {
    padding: 20px;
    #AboutBoxTwoRightSider {
      img {
        margin-top: 45px;
      }
    }

    #AboutBoxTwoLeftSider {
      h3 {
        // width: 100%;
        // height: 139px;
        margin: 10px 0px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 49px;
        text-transform: capitalize;
        color: #000000;
      }
      p {
        // width: 470px;
        // height: 180px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 180%;
        text-transform: capitalize;

        color: #515151;
      }
      #AboutinnerBox {
        display: flex;
        // width: 262px;

        justify-content: space-between;
        height: 90px;

        p {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 36px;
          line-height: 27px;
          /* or 47px */

          color: #f58220;
          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 130%;
            text-transform: capitalize;
            color: #626262;
          }
        }
      }
    }
  }

  #boxtwo {
    padding: 20px;
    #AboutLowerBoxoneLeftSider {
      img {
        margin-top: 65px;
      }
    }

    #AboutLowerBoxoneRightSide {
      h3 {
        // width: 426px;
        // height: 139px;
        margin: 10px 0px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 49px;
        text-transform: capitalize;
        color: #000000;
      }
      p {
        // width: 470px;
        // height: 180px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 180%;
        /* or 36px */

        text-transform: capitalize;

        color: #515151;
      }

      #AboutinnerBox {
        display: flex;
        // width: 262px;
        justify-content: space-between;

        height: 90px;

        // height: 90px;

        p {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 36px;
          line-height: 27px;
          /* or 47px */

          color: #f58220;
          span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 130%;
            text-transform: capitalize;
            color: #626262;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  #aboutlowersection #middlecardbox #aboutcardOne {
    width: 300px;
  }
  #aboutlowersection #middlecardbox #aboutcardtwo {
    width: 300px;
  }
  #aboutlowersection #middlecardbox #aboutcardthree {
    width: 300px;
  }

  #aboutLastsection #boxone #AboutBoxTwoLeftSider h3 {
    // margin: 10px 0px;
    // font-family: "Montserrat";
    // font-style: normal;
    // font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    // text-transform: capitalize;
    // color: #000000;
  }

  #aboutLastsection #boxtwo #AboutLowerBoxoneRightSide h3 {
    // margin: 10px 0px;
    // font-family: "Montserrat";
    // font-style: normal;
    // font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    // text-transform: capitalize;
    // color: #000000;
  }

  #aboutLastsection #boxone #AboutBoxTwoLeftSider p {
    font-size: 15px;
  }
  #aboutLastsection #boxtwo #AboutLowerBoxoneRightSide p {
    font-size: 15px;
  }
}

