@import "../utility/Function.scss";
@import "../utility/Variables";

.bg-gallery-head {
  padding: 40px 0px;

  h1 {
    @include text-style(34px, $font-color-two);
    font-weight: 300;

    span {
      @include text-style(34px, $font-color);
    }
  }
}

.Gallery {
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: white !important;
    background: #162337 !important;
    /* background-color: var(--bs-nav-pills-link-active-bg); */
  }
  a.nav-link {
    color: #000000 !important;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 25px !important;
    font-size: 14px;
    border: none !important;
  }
  .bttn {
    display: none;
  }

  // gallery head text degines
  .heading-text {
    text-align: center;
    padding: 20px 0px;

    h1 {
      @include text-style(32px, $font-color-three);
      position: relative;
      display: inline-block;

      &::after {
        position: absolute;
        bottom: -20px;
        left: 0px;
        content: "";
        height: 3px;
        width: 100%;
        background: rgba(38, 82, 97, 1);
      }
    }
  }
}

.CardContainer {
  .card {
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  .Gallery {
    a.nav-link {
      color: #000000 !important;
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
      border-radius: 25px !important;
      font-size: 12px;
      border: none !important;
    }
  }
}
