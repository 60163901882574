.achievements_wrap {
    padding: 80px 0px;
}

.achievements_wrap .achievements_details .h2_title {
    font-weight: 400;
    font-size: 48px;
    text-transform: capitalize;
    color: #265261;
}
.achievements_wrap .achievements_details .pera{
    line-height: 35px;
    font-size: 16px;
    padding: 30px 0px;
}
.achievements_wrap .achievements_details a{
    color: #004CE0;
    text-decoration: none;
}
.achievements_img{
    margin: 0px;
}
.achievements_img img{
    height: 300px;
    width: 100%;
    border-radius: 25px;
}
.achievements_wrap .row{
    align-items: center;
}
@media screen and (max-width: 991px) {
    .achievements_img{
        margin-bottom: 20px;
    }
    .achievements_wrap .achievements_details .h2_title{
        font-size: 40px;
    }
    .achievements_wrap .achievements_details .pera{
        padding: 20px 0px;
    }
}
@media screen and (max-width: 767px) {
    .achievements_wrap .achievements_details .h2_title{
        font-size: 30px;
    }
}
@media screen and (max-width: 575px) {
    .achievements_wrap{
        padding: 40px 0px;
    }
    .achievements_details{
        margin-bottom: 20px;
    }
}