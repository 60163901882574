.about_wrap {
    padding: 80px 0px;
    overflow: hidden;
}

.about_title {
    text-align: center;
    margin-bottom: 50px;
}

.about_title .h2_title {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    color: rgba(38, 82, 97, 1);
    font-weight: 600;
}

.about_title .h2_title::after {
    position: absolute;
    bottom: -3px;
    left: 0px;
    content: "";
    height: 3px;
    width: 100%;
    background: rgba(38, 82, 97, 1);
}

.about_details_wrap .about_details_title {
    max-width: 700px;
}

.about_details_wrap .about_details_title .h3_title {
    font-size: 40px;
    font-weight: 600;
    line-height: 55px;
    margin-bottom: 20px;
}

.about_details_wrap .about_details_title .h3_title span {
    color: rgba(245, 130, 32, 1);
}

.about_details_wrap .about_details_title .pera {
    font-size: 20px;
}

.about_details_wrap .about_details_title .about_img_wrap {
    margin: 0px;
    margin-top: 30px;
    position: relative;
}

.about_details_wrap .about_details_title .about_img_wrap img {
    border-radius: 10px;
}

.about_details_wrap .about_details_title .about_img_wrap .about_img_btn_wrap {
    position: absolute;
    bottom: 10px;
    z-index: 99;
    width: 100%;
}

.about_details_wrap .about_details_title .about_img_wrap .about_img_btn_wrap .about_img_btn {
    background-color: rgba(38, 82, 97, 1);
    color: #fff;
    display: block;
    margin: 0px auto;

}
.about_details_wrap .about_details_title .about_img_wrap .about_img_btn_wrap .about_img_btn a {
    background-color: rgba(38, 82, 97, 1);
    color: #fff;
    display: block;
    margin: 0px auto;
    text-decoration: none;
    text-transform: capitalize;

}
.about_details_wrap .about_details_title .about_img_wrap::after {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    content: "";
    border-radius: 10px;
    background-color: rgba(38, 82, 97, .5);
}

.about_details_wrap .about_ceo_wrap {
    margin-top: 30px;
}

.about_details_wrap .about_ceo_wrap .pera {
    padding: 10px 0px;
    color: rgba(245, 130, 32, 1);
}

.about_details_wrap .about_ceo_wrap .about_ceo_sig .pera {
    padding: 0px;
    color: #000;
    font-family: 'Passions Conflict', cursive;
    font-size: 40px;
    line-height: 40px;
    text-transform: capitalize;
}

.about_details_img {
    margin-bottom: 150px;
    position: relative;
    text-align: center;
}

.about_details_img .h6_title {
    text-transform: capitalize;
    text-align: center;
}

.about_details_img_wrap .about_details_img:last-child {
    margin: 0px;
}

.about_wrap .row {
    align-items: center;
}

.about_details_img::after {
    position: absolute;
    bottom: -245px;
    left: 50%;
    content: "";
    height: 100%;
    width: 2px;
    background-color: #000;
    transform: translate(-50%, -50%);
}

.about_details_img img {
    max-width: 100%;
}

.about_details_img_wrap .about_details_img:last-child::after {
    display: none;
}

@media screen and (max-width: 575px) {
    .about_wrap {
        padding: 40px 0px;
    }

    .about_title {
        margin-bottom: 20px;
    }
    .about_details_img::after{
        bottom: -217px;
    }
    .about_details_img_wrap{
        margin-top: 30px;
    }
}
@media screen and (max-width: 360px) {
    .about_details_img::after{
        bottom: -242px;
    }
}