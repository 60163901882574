#errorcomp {
  height: 100vh;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  display: block;
  padding: 50px;
  position: relative;
  background-image: linear-gradient(
    360deg,
    rgba(157, 219, 40, 0.973) 10%,
    rgba(40, 226, 179, 0.863) 100%
  );
  &::after {
    content: "";
    clip-path: circle(30% at left 80%);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0.4;
    background-image: linear-gradient
      (136deg, rgba(157, 219, 40, 0.973) 10%, rgba(40, 226, 179, 0.863) 100%);
    &::before {
      content: "";
      clip-path: circle(25% at right 80%);
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      opacity: 0.5;
      background-image: linear-gradient(
        135deg,
        rgba(157, 219, 40, 0.973) 10%,
        rgba(40, 226, 179, 0.863) 100%
      );
    }
  }
  #errortext {
    background-color: white;
    box-shadow: 10px 5px 50px 0px black;
    text-align: center;
    height: 100%;
    width: 100%;
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h2 {
      font-size: 68px;
      font-weight: 560;
    }

    #btns {
      display: flex;
      justify-content: center;

      #button {
        border: 1px solid black;
        margin: 10px 20px 0px 0px;
        padding: 10px 20px;
        text-align: center;
        font-family: "poppins";
        font-weight: 550;
        background-color: white;
        border-radius: 20px;

        &:hover {
          background-color: aquamarine;
        }
      }
    }
  }
}


@media screen and (max-width: 1540px) {
  // body {
  //   background-color: rgb(255, 208, 0);
  //   color: white;
  // }
}

// @media screen and (max-width: 1124px) {
//   body {
//     background-color: rgb(255, 0, 0);
//     color: white;
//   }
// }
// @media screen and (max-width: 332px) {
//   body {
//     background-color: blue;
//     color: white;
//   }
// }

// /* On screens that are 600px wide or less, the background color is olive */
// @media screen and (max-width: 600px) {
//   body {
//     background-color: olive;
//     color: white;
//   }
// }