#indushead {
  width: 100%;
  overflow: hidden;
  display: block;
  img {
    width: 100%;
  }
}

// indus
#indusbox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 20px;

  place-items: center;

  #induheadbox {
    width: 100%;

    display: block;
    #titile {
      width: 100%;
      p:nth-child(1) {
        margin-bottom: 0px;
        padding: 10px 0px;
        text-align: center;

        text-transform: capitalize;
        font-size: xx-large;
        color: rgba(0, 0, 0, 0.8);
        letter-spacing: 0.4px;
        font-weight: bolder;
      }
      p:nth-child(2) {
        text-decoration: none;
        text-align: center;
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
          sans-serif;
        font-style: normal;
        color: rgba(0, 0, 0, 0.7);
        letter-spacing: 0.6px;
        font-size: 18px;
        text-transform: capitalize;
      }
    }
  }

  #induimgbox {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0px 180px;
    -webkit-box-flex: wrap;
    // padding: 20px 100px;
    // background-color: red;
    #innerimgbox {
      margin: 20px;
      p {
        margin: 10px 0px;
        font-weight: bolder;
        text-transform: capitalize;
        letter-spacing: 0.4px;
      }
    }
  }
}


@media screen and (max-width: 1630px) {
    // body {
    //   background-color: rgb(255, 208, 0);
    //   color: white;
    // }
    #induimgbox {
      width: 100% !important;
      padding: 0px 80px !important;
   
    
    }
  }
  
  @media screen and (max-width: 1528px) {

    #induimgbox {
      width: 100% !important;
      padding: 0px 0px !important;

    }
 
  }
  @media screen and (max-width: 1374px) {
    #induimgbox {


    }
 
  }
  
  /* On screens that are 600px wide or less, the background color is olive */
  @media screen and (max-width: 1120px) {



    #induimgbox {

      padding: 0px 0px !important;
   
      #innerimgbox {
        margin: 20px;
        img{
          width: 540px !important;
          height: 350px !important;
        }
        
      }
    }
 
  }